<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-panel>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ $t("profile.edit") }}
            </div>

            <div class="subtitle-1 font-weight-light">
              {{ $t("profile.complete") }}
            </div>
          </template>

          <v-form @submit.prevent="updateProfile" ref="form">
            <v-container class="py-0">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="
                      $t('profile.email') + ' / ' + $t('profile.username')
                    "
                    class="purple-input"
                    v-model="form.email"
                    disabled
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    v-model="form.first_name"
                    :rules="rules.first_name"
                    :label="$t('profile.first_name')"
                    @input="resetInputError('first_name')"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    v-model="form.last_name"
                    :rules="rules.last_name"
                    :label="$t('profile.last_name')"
                    @input="resetInputError('last_name')"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('profile.company')"
                    v-model="form.company"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('profile.job_title')"
                    v-model="form.job_name"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    v-model="form.address"
                    :label="$t('profile.address')"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('profile.city')"
                    class="purple-input"
                    v-model="form.city"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.postcode"
                    class="purple-input"
                    :label="$t('profile.postcode')"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('profile.country')"
                    class="purple-input"
                    v-model="form.country"
                  />
                </v-col>

                <v-col cols="12">
                  <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
                    $t(errorMessage)
                  }}</v-alert>
                  <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
                    $t("profile.User saved")
                  }}</v-alert>
                  <v-btn
                    color="primary"
                    class="mr-0 float-right mt-3"
                    type="submit"
                  >
                    {{ $t("profile.update") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-panel>
      </v-col>

      <v-col cols="12" md="4">
        <base-panel class="v-card-profile">
          <v-card-text class="text-center">
            <v-avatar
              v-if="image_url"
              size="128"
              class="mx-auto elevation-4"
              color="grey"
            >
              <v-img :src="image_url" />
            </v-avatar>
            <update-image @imageUpdated="changeImage"></update-image>
            <update-password></update-password>
          </v-card-text>
        </base-panel>

        <base-panel class="v-card-subscription" v-if="form.subscription">
          <v-card-text class="text-center">
          <h3 class="mb-2">{{ $t("subscription.subscription")}}: {{form.subscription.name}}</h3>
          <h4 class="mb-2">{{ $t("subscription.until")}}: {{subUntil}}</h4>
          <h4 class="mb-2">{{ $t("subscription.users")}}: {{form.subscription.active_users}}/{{form.subscription.paid_seats}}</h4>
          <br>
            <a class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary" target="admin" :href="adminUrl">{{ $t("subscription.manage")}}</a>
          </v-card-text>
        </base-panel>
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
import crudForm from "mixins/crudForm";
//import validate from "@/plugins/validate";
import UpdateImage from "@/pages/users/UsersImageUpdate";
import UpdatePassword from "@/pages/users/UserPasswordUpdate";
export default {
  components: {
    UpdateImage,
    UpdatePassword,
  },
  mixins: [crudForm],
  created() {
    this.loadProfile();
  },
  data() {
    return {
      apiQuery: "/users/",
      file: "",
      disabled: 1,
      changed: false,
      error: false,
      image_url: "/img/avatardefault.png",
      pwd_form: {},
      rules: {
        first_name: [
          () =>
            this.getInputError("first_name", {
              min: 2,
              max: 30,
              name: this.$t("profile.first_name"),
            }),
        ],
        last_name: [
          () =>
            this.getInputError("last_name", {
              min: 2,
              max: 30,
              name: this.$t("profile.last_name"),
            }),
        ],
      },
    };
  },
  methods: {
    loadProfile(uri = "/profile") {
      this.get(uri).then((data) => {
        this.form = data;
        this.image_url = data.image_url
          ? "/storage/" + data.image_url
          : "/img/avatardefault.png";
      });
    },
    changeImage(data) {
      this.image_url = data;
    },
    updateProfile() {
      this.errorMessage = null;
      this.serverErrors = null;
      if (!this.$refs.form.validate()) {
        return;
      }
      this.patch("/update", this.form)
        .then((res) => {
          this.savedItem = res;
          setTimeout(() => {
            this.savedItem = null;
          }, 2500);
        })
        .catch((error) => {
          this.errorMessage = error.message || error.error;
          this.serverErrors = error.errors;
          this.$refs.form.validate();
          this.$emit("serverError", error);
        });
    },
  },
  computed: {
    subUntil() {
      if(!this.form) {
        return "-";
      }
      var date = new Date(this.form.subscription.paid_until * 1000);
      return date.toLocaleDateString(this.$i18n.locale);
    },
    adminUrl() {
      return process.env.VUE_APP_FINADMIN_URL + "/abo/finsupport";
    }
  }
};
</script>
