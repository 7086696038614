<template>
  <v-form
    id="formProfile"
    ref="form"
    @submit.prevent="saveItem"
    lazy-validation
  >
    <v-file-input
      id="profile-image"
      name="profile-image"
      @change="updateProfileImage"
      accept="image/*"
      :label="$t('profile.profile_image')"
    ></v-file-input>
  </v-form>
</template>

<script>

export default {
  data() {
    return {
      apiQuery: "/upload-picture",
    };
  },
  methods: {
    updateProfileImage() {
      const form = document.getElementById("formProfile");
      this.upload(this.apiQuery, new FormData(form))
        .then((data) => {

          this.$emit("imageUpdated", data.url);
        })
        .catch((error) => {
          this.errorMessage = error.message || error.error;
          this.serverErrors = error.errors;
          // this.$refs.form.validate();
          this.$emit("serverError", error);
        });
    },
  },
};
</script>
